<template>
  <v-container
    fluid
    fill-height
    class="d-flex justify-center align-center"
    style="background-color: white"
  >
    <v-row>
      <v-col class="text-center">
        <img
          class="col-12 "
          :src="preview_item.product_img"
          style="width: 80vw; padding-bottom: 10px"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center text-h6 font-weight-bold">
        {{ preview_item.brand_name }}
      </v-col>
      <v-col cols="12" class="text-center text-h6">
        {{ preview_item.product_name }}
      </v-col>
      <v-col cols="12" class="text-center text-caption" v-if="isPresent">
        <span class="font-weight-bold"> {{ send_user }}</span>로부터 받은 선물
      </v-col>
      <v-col
        cols="12"
        v-if="preview_item.expire_date !== null"
        class="text-center text-caption"
      >
        유효기간
        <span class="font-weight-bold"> {{ preview_item.expire_date }} </span>
      </v-col>
    </v-row>

    <!-- is OTC **************************************************************************** -->
    <v-row v-if="isOtc" class="justify-space-around ">
      <v-col v-if="isIssued" cols="12" class="text-center">
        <barcode
          :value="barcodeNumber"
          tag="svg"
          :options="{ width: 3, height: 60, fontSize: 32 }"
        >
          Show this if the rendering fails.
        </barcode>
      </v-col>
      <v-col cols="12" v-if="!isIssued">
        <v-btn block color="primary" @click="issued" style="min-width: 100px;" rounded large>
          바코드발급
        </v-btn>
      </v-col>

      <v-col cols="12" v-if="!isIssued">
        <v-btn block @click="sendGift" rounded large>
          선물하기
        </v-btn>
      </v-col>

      <v-col cols="12" v-if="!isIssued&&!isPresent">
        <v-btn block @click="openRepayDia = true" rounded large>
          환불
        </v-btn>
      </v-col>

      <v-col cols="12" v-if="!isIssued">
        <v-btn block @click="goBack" rounded large>
          취소
        </v-btn>
      </v-col>

      <v-col cols="12" v-if="isIssued">
        <v-btn
          class="text-center justify-center"
          block
          color="primary"
          large 
          rounded
          @click="$router.go(-1)"
        >
          사용완료
        </v-btn>
      </v-col>
    </v-row>

    <!-- Else **************************************************************************** -->
    <v-row v-else>
      <v-col>
        <v-card flat class="text-cente justify-center">
          <v-card-text>
            <v-row class="text-center">
              <v-col class="text-center">
                <barcode
                  v-if="isBarcode"
                  :value="barcodeNumber"
                  tag="svg"
                  :options="{ width: 3, height: 60, fontSize: 32 }"
                >
                  Show this if the rendering fails.
                </barcode>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text v-if="isBarcode" class="text-center justify-center">
            <v-spacer></v-spacer>
            <div class="text-center justify-center">
              <v-row>
                <v-col cols="12">
                  <v-btn
                    v-if="isGift"
                    class="text-center justify-center"
                    block
                    color="primary"
                    @click="terminateGift"
                    style="min-width: 100px;"
                    rounded
                    large
                  >
                    사용완료
                  </v-btn>
                  <!-- <v-btn
                v-if="!isGift"
                class="text-center justify-center"
                color="green darken-1"
                text
                @click="goHome"
              >
                사용완료 확인
              </v-btn> -->
                </v-col>
                <v-col cols="12">
                  <v-btn block @click="sendGift" style="min-width: 100px;" rounded large>
                    선물하기
                  </v-btn>
                </v-col>
                <!-- <v-col cols="12">
                  <v-btn block @click="sellGift" style="min-width: 100px;" rounded>
                    판매하기
                  </v-btn>
                </v-col> -->
                <v-col cols="12">
                  <v-btn block @click="goBack" style="min-width: 100px;" rounded large>
                    취소
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay :value="loading_bar">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- *************************************************************************** -->
    <!-- confirm_dialog : Start  ******************************************************* -->
    <v-dialog v-model="confirm_dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5 justify-center"> 주의 </v-card-title>

        <v-card-text>
          {{ notice_ment }}
        </v-card-text>

        <v-card-text>
          <v-spacer></v-spacer>

          <v-btn
            class="white--text mr-3"
            color="grey"
            @click="confirm_dialog = false"
            rounded 
            large
          >
            취소
          </v-btn>

          <v-btn color="primary" @click="openBarcode" rounded large>
            바코드 발급받기
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- confirm_dialog : End  ************************************************************ -->
    <!-- ****************************************************************************** -->

    <!-- *************************************************************************** -->
    <!-- Barcode :  Start  ******************************************************* -->
    <v-dialog v-model="barcode_dialog" max-width="290">
      <v-card>
        <!-- <v-card-title class="text-h5"> 바코드 </v-card-title> -->

        <v-card-text>
          <v-row class="text-center">
            <v-col class="text-center">
              <barcode v-if="isBarcode" :value="barcodeNumber">
                Show this if the rendering fails.
              </barcode>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-spacer></v-spacer>
          <div class="text-center justify-center">
            <v-btn color="green darken-1" text @click="barcode_dialog = false">
              취소
            </v-btn>

            <v-btn color="green darken-1" text @click="terminateGift">
              사용완료
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Barcode :  End  **************************************-->
    <!-- *************************************************************************** -->
    <!-- *************************************************************************** -->
    <!-- Dialog : Calendar Start *******************************-->
    <v-dialog
      v-model="terminate_dialog"
      hide-overlay
      persistent
      width="auto"
      transition="dialog-left-transition"
    >
      <v-card class="text-center mb-3">
        <v-card-title class="justify-center text-h5">
          기프티콘 삭제안내
        </v-card-title>
        <v-card-text>{{ terminate_msg }} </v-card-text>
        <v-btn color="primary" class="mb-3" @click="goHome"  rounded large>
          확인
        </v-btn>
      </v-card>
    </v-dialog>
    <!-- Dialog : Calendar End *********************************** -->
    <!-- Dialog : Calendar Start ********************************* -->
    <v-dialog
      v-model="openRepayDia"
      hide-overlay
      persistent
      width="auto"
      transition="dialog-left-transition"
    >
      <v-card class="text-center mb-3">
        <v-card-title class="justify-center text-h5">
          환불안내
        </v-card-title>
        <v-card-text>{{ repayment_msg }} </v-card-text>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="grey"
              class="mb-3 white--text mr-3"
              @click="openRepayDia = false"
              rounded 
              large
            >
              취소
            </v-btn>
            <v-btn color="primary" class="mb-3" @click="repayment"  rounded large>
              확인
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- Dialog : Calendar End *********************************** -->
    <!-- is Present ***************************************************** -->
    <v-dialog
        v-model="presConfirmDia"
        hide-overlay
        transition="dialog-left-transition"
      >
        <v-card>
          <v-card-title class="text-h5 justify-center"> 알림 </v-card-title>
          <v-card-text>{{ present_msg }}</v-card-text>
          <v-card-text class="text-center">
            <v-btn color="primary" rounded large @click="confirmPresent">
              확인
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- is Present End ************************************************ -->
    <!-- *************************************************************************** -->
  </v-container>
</template>

<style scoped>
img {
  width: 650px;
  padding: 5px;
  /* border: 2px solid #fcbe14; */
  background-color: #ffffff;
}

.vue-barcode-element {
  outline: 6px solid white;
  z-index: 10;
}
</style>

<script>
import VueBarcode from "vue-barcode";
import axios from "../../../service/axios";

export default {
  name: "Page",
  components: {
    barcode: VueBarcode,
    //   OtcFooter: () => import("@/components/OtcFooter"),
  },

  props: ["item"],

  data() {
    return {
      isOtc: false,
      isIssued: false,
      isGift: true,
      own_data: {
        cash: null,
        own_gift_datas: [],
      },
      preview_item: {},
      isBarcode: false,
      barcodeNumber: null,
      loading_bar: false,
      confirm_dialog: false,
      notice_content: "사용 완료하시면 기프티콘 지갑에서 사용처리됩니다",
      notice_ment: "text",
      barcode_dialog: false,
      terminate_dialog: false,
      terminate_msg: null,
      openRepayDia: false, // 환불Dialog open
      repayment_msg:
        "환불은 5% 수수료를 제하고 포인트로 입금됩니다. 환불하시려면 확인을 누르세요.",
      isPresent: false, 
      presConfirmDia: false,
      present_msg:'선물을 받으셨습니다',
      send_user: '',
    }; // return
  }, // data

  computed: {
    // ...mapState('G_NAME', [])
  }, // computed

  mounted() {
    window.scrollTo(0, 0);

    this.notice_ment =
      '사용하시려면 아래 "바코드 발급받기"버튼을 눌러주세요. \n확인을 누르시면 바로 삭제됩니다. \n그래도 확인을 누르시겠습니까?';
    // else this.notice_ment = '유효기간안에 꼭 사용해 주세요. 매장에서 사용하신후 아래 "사용완료" 버튼을 눌러 지갑에서 삭제시켜주세요'
    if (typeof this.item !== "undefined") {
      console.log("IF.................");
      this.preview_item = this.item;
      console.log("Gift Preview Item..... : ", this.item);
    } else {
      console.log("ELSE.................");
      this.preview_item = this.$store.getters.getCurrentItem;
    }
    if(this.preview_item.history.length>0){
      this.isPresent = true
      const length = this.preview_item.history.length-1
      const user_email = this.preview_item.history[length].owned_by
      this.send_user = user_email
      this.present_msg = `${user_email}님께서 선물하셨습니다`
    }
    if (this.preview_item.barcode) {
      this.isBarcode = true;
      this.barcodeNumber = this.preview_item.barcode;
    }
    if (this.preview_item.article_code !== "") this.isOtc = true

    if(this.preview_item.is_nft == true) this.presConfirmDia = true

    console.log("this.barcodeNumber : . : ", this.barcodeNumber);
  }, // mounted

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    confirmPresent() {
      // api change is_nft = false 
      this.presConfirmDia = false
    },

    async repayment() {
      this.openRepayDia = false;
      this.loading_bar = true;
      let options = {
        accessKey: this.$cookies.get("token"),
        _id: this.preview_item._id,
        article_code: this.preview_item.article_code,
        sale_price: this.preview_item.sale_price
      };
      let repayment_raw = await axios.post("/gift/repayment", options);
      if (repayment_raw) {
        console.log("repayment.............", repayment_raw.data.data.item);
        const userBalance = repayment_raw.data.data.item.user_balance
        this.$store.commit({ type: "setBalance", balance: userBalance });
        this.loading_bar = false;
        this.$router.go(-1);
      }
    },

    formatPrice(value) {
      try {
        return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      } catch {
        return value;
      }
    },

    canceltPreviewDialog() {
      // this.shopview_dialog = false;
      this.$router.go(-1);
    },

    issued() {
      this.notice_ment =
        '지금 사용하시려면 아래 "바코드 발급받기"버튼을 눌러주세요. \n클릭하시면 본 기프티콘은 바로 사용처리되어 지갑에서 삭제됩니다.';
      this.confirm_dialog = true;
    },

    async openBarcode() {
      console.log("Article_code : ", this.preview_item.article_code);

      this.confirm_dialog = false;
      this.preview_item.del_yn = true;
      const isArticle = this.preview_item.article_code;
      let giftData = {};

      if (isArticle !== "") {
        let options = {
          _id: this.preview_item._id,
          article_code: this.preview_item.article_code,
        };
        let barcode_raw = await axios.get("/product/getOldestProductBarcode", {
          params: options,
        });
        console.log("Get barcode : ", barcode_raw.data.data.item);
        giftData = barcode_raw.data.data.item;
        giftData["product_img"] = giftData["gift_img_url"];
        this.notice_ment =
          '유효기간안에 꼭 사용해 주세요. 매장에서 사용하신후 아래 "사용완료" 버튼을 눌러 지갑에서 삭제시켜주세요';
        this.isBarcode = true;
        this.barcodeNumber = giftData.barcode;
        this.preview_item = giftData;

        let delOptions = {
          _id: this.preview_item._id,
          barcode: this.barcodeNumber,
          article_code: this.preview_item.article_code,
          del_yn: true,
        };
        const params = {
          accessKey: this.$cookies.get("token"),
          options: delOptions,
        };
        console.log("front params.options : ", params.delOptions);
        // if (this.preview_item.gift_type == "mygift") {
        await axios
          .post("/gift/delete", params)
          .then((res) => {
            console.log(res.data.data.item);
            this.isIssued = true;
            this.own_data.own_gift_datas = [];
          })
          .catch((error) => {
            console.log("페스트 오류", error);
          });
      } else {
        // this.isBarcode = true
        this.barcodeNumber = this.preview_item.barcode;

        let delOptions = {
          _id: this.preview_item._id,
          del_yn: true,
        };
        const params = {
          accessKey: this.$cookies.get("token"),
          options: delOptions,
        };
        console.log("front params.options : ", params.delOptions);
        // if (this.preview_item.gift_type == "mygift") {
        await axios
          .post("/gift/delete", params)
          .then((res) => {
            console.log(res.data.data.item);
            this.own_data.own_gift_datas = [];
          })
          .catch((error) => {
            console.log("페스트 오류", error);
          });
      }

      // }
      // this.$router.go(-1);
    },

    async sendGift() {
      const item = this.preview_item;
      this.$store.commit({ type: "setSubPage", sub_page: "선물하기" });
      this.$store.commit({ type: "setCurrentItem", currentItem: item });
      this.$router.push({
        name: "sendGift",
        params: { gift: JSON.stringify(this.preview_item) },
      });
    },

    sellGift() {
      console.log("sell Gift.....");
      // this.$store.commit({ type: 'setMainPage', main_page: 1});
      // this.$EventBus.$emit('openSellTab')
      this.$router.go(-1)
      // this.$store.commit({ type: "setSubPage", sub_page: "판매" });
      // this.$router.replace({
      //   name: "sellGift",
      //   params: { gift: this.preview_item },
      // }); //
    },

    goHome() {
      this.$router.replace("/home/home");
    },

    terminateGift() {
      this.loading_bar = true;
      this.updateAndDeleteGift();
      console.log("Terminate.....");
    },

    async updateAndDeleteGift() {
      // if (this.preview_item.article_code !== "") {
      //   return;
      // } else {
      let options = {
        _id: this.preview_item._id,
        del_yn: true,
      };
      const params = {
        accessKey: this.$cookies.get("token"),
        options: options,
      };

      console.log("front params.options : ", params.options);
      // if (this.preview_item.gift_type == "mygift") {
      await axios
        .post("/gift/delete", params)
        .then((res) => {
          console.log(res.data.data.item);
          this.own_data.own_gift_datas = [];
          // this.ownGiftInfo();
          // this.req_load_my_gift();
          // this.$router.replace("/home/delete");
          this.isGift = false;
          this.loading_bar = false;
          this.terminate_msg = "사용한 기프티콘이 삭제됐습니다";
          this.terminate_dialog = true;
        })
        .catch((error) => {
          console.log("페스트 오류", error);
        });
      // }
      // this.$router.go(-1);
      // }
    },

    async ownGiftInfo() {
      const params = {
        accessKey: this.$cookies.get("token"),
        options: false,
      };
      await axios
        .post("/api/user/ownProductInfo", params)
        .then((res) => {
          this.loading_bar = false;

          for (var gift of res.data.data) {
            gift["gift_type"] = "product";
            this.own_data.own_gift_datas.push(gift);
          }
        })
        .catch((error) => {
          this.loading_bar = false;
          console.log("페스트 오류", error);
        });
    },

    // async req_load_my_gift() {
    //     var options = {
    //         del_yn: false,
    //     };
    //     const params = {
    //         accessKey: this.$cookies.get("token"),
    //         options: options,
    //     };
    //     await axios
    //         .post("/gift/list", params)
    //         .then((res) => {
    //             var gifts = res.data.data.item;
    //             console.log("Gifts ::: ", gifts);

    //             let tempGift =[]
    //             for (var gift of gifts) {
    //                 gift["gift_type"] = "mygift";
    //                 gift["product_img"] = gift["gift_img_url"];
    //                 this.own_data.own_gift_datas.push(gift);
    //                 tempGift.push(gift)
    //             }

    //             this.$store.commit({ type: "setMyGifts", myGifts: tempGift });

    //         })
    //         .catch((error) => {
    //             console.log("페스트 오류", error);
    //         });
    // },
  }, // methods
};
</script>
